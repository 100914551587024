import { ActionTypes } from "./constants";

export type FormActionType = {
  type:
  | ActionTypes.FETCH_FORM
  | ActionTypes.CREATE_FORM
  | ActionTypes.UPDATE_FORM
  | ActionTypes.SET_FORM
  | ActionTypes.SET_FORM_SUCCESS
  | ActionTypes.ENQUIRY_FORM
  | ActionTypes.FETCH_SINGLE_FORM
  | ActionTypes.SET_SINGLE_FORM
  | ActionTypes.SET_ENQUIRY_FORM
  | ActionTypes.FETCH_ALL_FORM
  | ActionTypes.SET_ALL_FORM
  | ActionTypes.UPDATE_FORM_SUCCESS
  | ActionTypes.SET_ERROR
  | ActionTypes.FETCH_FORM_ANSWER
  | ActionTypes.SET_FORM_ANSWER
  | ActionTypes.SET_FORM_ANSWER_SUCCESS
  | ActionTypes.DELETE_FORM
  | ActionTypes.DELETE_FORM_SUCCESS
  | ActionTypes.DELETE_FORM_FAIL
  | ActionTypes.FETCH_FORM_STATUS
  | ActionTypes.SET_FORM_STATUS
  | ActionTypes.FETCH_SINGLE_FORM_BY_TYPE
  | ActionTypes.FETCH_FORM_BY_UUID
  | ActionTypes.SET_FORM_BY_UUID_SUCCESS
  | ActionTypes.SET_FORM_BY_UUID_FAIL

  payload: {} | string;
};



export const getForm = (data: any): FormActionType => ({
  type: ActionTypes.FETCH_FORM,
  payload: { data },
});

export const getFormAnswer = (data: any): FormActionType => ({
  type: ActionTypes.FETCH_FORM_ANSWER,
  payload: { data },
});

//create product
export const saveForm = (data: any): FormActionType => ({
  type: ActionTypes.CREATE_FORM,
  payload: { data },
});

//update product
export const updateForm = (data: any): FormActionType => ({
  type: ActionTypes.UPDATE_FORM,
  payload: { data },
});

//get enquiry form
export const enquiryForm = (data: any): FormActionType => ({
  type: ActionTypes.ENQUIRY_FORM,
  payload: { data },
});

//get single form
export const singleForm = (id: any): FormActionType => ({
  type: ActionTypes.FETCH_SINGLE_FORM,
  payload: { id },
});

//get all form
export const getAllForm = (data: any): FormActionType => ({
  type: ActionTypes.FETCH_ALL_FORM,
  payload: { data },
});

//get all form
export const getFromStatus = (): FormActionType => ({
  type: ActionTypes.FETCH_FORM_STATUS,
  payload: {},
});

//get single form
export const singleFormByType = (type: string, subType: number): FormActionType => ({
  type: ActionTypes.FETCH_SINGLE_FORM_BY_TYPE,
  payload: { type, subType },
});


//enquiry action to  dispatch
export const formApiResponse = (
  actionType: string,
  data: any
): FormActionType => {
  switch (actionType) {
    case ActionTypes.SET_FORM_SUCCESS:
      return {
        type: ActionTypes.SET_FORM_SUCCESS,
        payload: { actionType, data },
      };
    case ActionTypes.SET_FORM:
      return {
        type: ActionTypes.SET_FORM,
        payload: { actionType, data },
      };
    case ActionTypes.SET_ALL_FORM:
      return {
        type: ActionTypes.SET_ALL_FORM,
        payload: { actionType, data },
      };
    case ActionTypes.SET_SINGLE_FORM:
      return {
        type: ActionTypes.SET_SINGLE_FORM,
        payload: { actionType, data },
      };
    case ActionTypes.SET_ENQUIRY_FORM:
      return {
        type: ActionTypes.SET_ENQUIRY_FORM,
        payload: { actionType, data },
      };
    case ActionTypes.UPDATE_FORM_SUCCESS:
      return {
        type: ActionTypes.UPDATE_FORM_SUCCESS,
        payload: { actionType, data },
      };
    case ActionTypes.SET_FORM_ANSWER:
      return {
        type: ActionTypes.SET_FORM_ANSWER,
        payload: { actionType, data },
      };
    case ActionTypes.FETCH_FORM_STATUS:
      return {
        type: ActionTypes.SET_FORM_STATUS,
        payload: { actionType, data }
      }
    default:
      return {
        type: ActionTypes.SET_ERROR,
        payload: { actionType, error: "Error While setting data to store ! " },
      };
  }
};

//api response error handling
export const formResponseError = (
  actionType: string,
  error: any
): FormActionType => ({
  type: ActionTypes.SET_ERROR,
  payload: { actionType, error },
});

//! DELETING Form
export const deleteForm = (data: any): FormActionType => ({
  type: ActionTypes.DELETE_FORM,
  payload: { data }
})

export const deleteFormResponse = (actionType: any, data: any): FormActionType => ({
  type: ActionTypes.DELETE_FORM_SUCCESS,
  payload: { actionType, data }
})
export const deleteFormError = (actionType: any, error: any): FormActionType => ({
  type: ActionTypes.DELETE_FORM_FAIL,
  payload: { actionType, error }
})


//! Get form by UUID

export const getFormByUUID = (data: any): FormActionType => ({
  type: ActionTypes.FETCH_FORM_BY_UUID,
  payload: { data }
})

export const getFormByUUIDResponse = (actionType: string, data: any): FormActionType => ({
  type: ActionTypes.SET_FORM_BY_UUID_SUCCESS,
  payload: { actionType, data }
})

export const getFormByUUIDError = (actionType: string, error: any): FormActionType => ({
  type: ActionTypes.SET_FORM_BY_UUID_FAIL,
  payload: { actionType, error }
})


