export enum ActionTypes {
    SET_ERROR = "SET_PRODUCT",
    FETCH_FORM = "FETCH_FORM",
    CREATE_FORM = "CREATE_FORM",
    SET_FORM = "SET_FORM",
    SET_FORM_SUCCESS = "SET_FORM_SET_SUCCESS",
    UPDATE_FORM="UPDATE_FORM",
    FETCH_SINGLE_FORM="FETCH_SINGLE_FORM",
    FETCH_SINGLE_FORM_BY_TYPE = "FETCH_SINGLE_FORM_BY_TYPE",
    SET_SINGLE_FORM="SET_SINGLE_FORM",
    FETCH_ALL_FORM="FETCH_ALL_FORM",
    ENQUIRY_FORM="ENQUIRY_FORM",
    SET_ALL_FORM="SET_ALL_FORM",
    SET_ENQUIRY_FORM="SET_ENQUIRY_FORM",
    UPDATE_FORM_SUCCESS="UPDATE_FORM_SUCCESS",
    FETCH_FORM_ANSWER = "FETCH_FORM_ANSWER",
    SET_FORM_ANSWER = "SET_FORM_ANSWER",
    SET_FORM_ANSWER_SUCCESS = "SET_FORM_ANSWER_SUCCESS",
    DELETE_FORM="DELETE_FORM",
    DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS",
    DELETE_FORM_FAIL = "DELETE_FORM_FAIL",
    
    FETCH_FORM_STATUS="FETCH_FORM_STATUS",
    SET_FORM_STATUS="SET_FORM_STATUS",

    FETCH_FORM_BY_UUID="FETCH_FORM_BY_UUID",
    SET_FORM_BY_UUID_SUCCESS="SET_FORM_BY_UUID_SUCCESS",
    SET_FORM_BY_UUID_FAIL="SET_FORM_BY_UUID_FAIL",

  }
  