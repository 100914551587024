import { useState } from "react";
import useDebounce from 'pages/customer/hooks/useDebounce';

    
    
export default function useSearch(debounceDelay?: number, defaultSearchString?: string) {
    
    const [searchQuery, setSearchQuery] = useState(defaultSearchString ?? "");
    const debouncedSearchString = useDebounce(searchQuery, debounceDelay ?? 50);

    return {
        setSearchQuery, debouncedSearchString
    }
}